import React, { useState, useEffect, useContext } from "react"
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js"
import AppContext from "../contexts/app/context"

// const { GATSBY_API_HOST } = process.env
const GATSBY_API_HOST = "https://hamzatanjicherkaoui.com/api"
export default function checkout() {
  const [clientToken, setClientToken] = useState(null)
  const { cart: products, updateCart, updateQuantity } = useContext(AppContext)

  useEffect(() => {
    if (!products.length) {
      const jsonCart = localStorage.getItem("cart")
      const localCart = JSON.parse(jsonCart)
      updateCart(localCart || [])
    }
  }, [])

  useEffect(() => {
    ;(async () => {
      const response = await (
        await fetch(`${GATSBY_API_HOST}/paypal/generate-client-token`)
      ).json()
      setClientToken(response?.client_token || response?.clientToken)
    })()
  }, [])
  return (
    <>
      <section>
        <h1 className="sr-only">Checkout</h1>

        <div className="mx-auto grid w-1/2 grid-cols-1 md:grid-cols-2">
          <div className="bg-gray-50 py-12 md:py-24">
            <div className="mx-auto max-w-lg space-y-8 px-4 lg:px-8">
              <div>
                <p className="text-4xl text-center font-light tracking-tight text-purple-900">
                  ${" "}
                  {products
                    .map(e => e.price * e.quantity)
                    .reduce((a, b) => a + b, 0)}
                </p>

                <p className="mt-1 text-sm text-gray-600">
                  For the purchase of
                </p>
              </div>

              <div>
                <div className="flow-root">
                  <div className="-my-4 divide-y divide-gray-100">
                    {products.map(product => (
                      <div className="mt-4">
                        <div className="flex items-center justify-between mb-4">
                          <div className="flex items-center">
                            <img
                              className="w-12 h-12 rounded"
                              src={`${GATSBY_API_HOST}/download/${product.image}`}
                              alt="Product 1"
                            />
                            <div className="ml-2">
                              <h3 className="text-gray-800">{product.name}</h3>
                              <p className="text-gray-600">
                                Price: ${product.price}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="bg-white py-12 md:py-24">
            <div className="mx-auto max-w-lg px-4 lg:px-8">
              {!clientToken && (
                <div className=" py-12 px-6  bg-gray-100 rounded-2xl text-sm text-center text-purple-600 font-light animate-pulse">
                  <p>Loading Payment Methods ...</p>
                </div>
              )}
              {clientToken && (
                <PayPalScriptProvider
                  options={{
                    clientId: process.env.GATSBY_CLIENT_ID,
                    components: "buttons,hosted-fields",
                    intent: "capture",
                    vault: false,
                    dataClientToken: clientToken,
                  }}
                >
                  {/* return fetch(GATSBY_API_HOST+"/orders", { */}
                  <PayPalButtons
                    style={{ layout: "vertical" }}
                    createOrder={async (data, actions) => {
                      try {
                        const response = await fetch(
                          `${GATSBY_API_HOST}/paypal/orders`,

                          {
                            body: JSON.stringify({
                              type: "cart",
                              cart: products,
                            }),
                            method: "POST",
                            headers: {
                              "Content-Type": "application/json",
                            },
                          }
                        )

                        const details = await response.json()
                        return details.id
                      } catch (error) {
                        console.error(error)
                        // Handle the error or display an appropriate error message to the user
                      }
                    }}
                    onApprove={async (data, actions) => {
                      console.log("onApprove , data", data)
                      try {
                        const response = await fetch(
                          `${GATSBY_API_HOST}/paypal/orders/${data.orderID}/capture`,
                          {
                            method: "POST",
                          }
                        )

                        const details = await response.json()
                        // Three cases to handle:
                        //   (1) Recoverable INSTRUMENT_DECLINED -> call actions.restart()
                        //   (2) Other non-recoverable errors -> Show a failure message
                        //   (3) Successful transaction -> Show confirmation or thank you message

                        // This example reads a v2/checkout/orders capture response, propagated from the server
                        // You could use a different API or structure for your 'orderData'
                        const errorDetail =
                          Array.isArray(details.details) && details.details[0]

                        if (
                          errorDetail &&
                          errorDetail.issue === "INSTRUMENT_DECLINED"
                        ) {
                          return actions.restart()
                          // https://developer.paypal.com/docs/checkout/integration-features/funding-failure/
                        }

                        if (errorDetail) {
                          let msg =
                            "Sorry, your transaction could not be processed."
                          msg += errorDetail.description
                            ? ` ${errorDetail.description}`
                            : ""
                          msg += details.debug_id
                            ? ` (${details.debug_id})`
                            : ""
                          alert(msg)
                        }

                        // Successful capture! For demo purposes:
                        console.log(
                          "Capture result",
                          details,
                          JSON.stringify(details, null, 2)
                        )
                        const transaction =
                          details.purchase_units[0].payments.captures[0]
                        alert(
                          `Transaction ${transaction.status}: ${transaction.id}See console for all available details`
                        )
                      } catch (error) {
                        console.error(error)
                        // Handle the error or display an appropriate error message to the user
                      }
                    }}
                  />
                </PayPalScriptProvider>
              )}
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export function Head() {
  return (
    <meta
      httpEquiv="Content-Security-Policy"
      content="upgrade-insecure-requests"
    />
  )
}
